import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Error from './pages/Error';
import Widget from './pages/Widget.routes';
import Main from 'modules/Main';
import Testing from 'modules/Testing';
import ValidasiSpt from 'modules/ValidasiSpt';
import ValidasiSptError from 'modules/ValidasiSptError';

// import KonfirmasiSpt from 'modules/KonfirmasiSpt';
// import HasilSpt from 'modules/HasilSpt';
// import AuthCheck from './modules/AuthCheck';
// import Payment from 'modules/Payment';
// import Error from 'modules/Error';

const routes = () => (
  <Switch>
    <Route path="/" exact component={() => (<Redirect to="/main" />)}/>
    <Route path="/main" render={(props) => (
      <Main {...props}/>
    )}/>
    <Route path="/testing" render={(props) => (
      <Testing {...props}/>
    )}/>
    {/* <Route path="/validasi-spt" render={(props) => (
      <ValidasiSpt {...props}/>
    )}/> */}
    <Route path="/auth" render={(props) => (
      <Widget {...props}/>
    )}/>
    <Route path="/validasi-spt" render={(props) => (
      <ValidasiSpt router={props}/>
    )}/>
    {/*
    <Route path="/konfirmasi-spt" render={(props) => (
      <KonfirmasiSpt router={props}/>
    )}/>
    <Route path="/hasil-spt" render={(props) => (
      <HasilSpt router={props}/>
    )}/>
    <Route path="/auth/check" render={(props) => (
      <AuthCheck router={props}/>
    )}/> */}
    <Route path="/error" render={(props) => (
      <Error router={props}/>
    )}/>
    <Route path="/validasi-spt-error" render={(props) => (
      <ValidasiSptError {...props}/>
    )}/>
  </Switch>
);

export default routes
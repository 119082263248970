/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import Logo from '../../libs/react-mpk/components/Logo'
import './AuthCheck.scss';
import qs from 'query-string';
import * as service from './AuthCheckService';
import { authService } from 'services';
import { inject, observer } from 'mobx-react';
import { http } from '../../libs/react-mpk/services';

class AuthCheck extends Component{

  componentDidMount(){
    // this.getConfigHeadersAndHost();
    this.getDjpAuthorize();
  }

  getDjpAuthorize = async () => {
    // window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=ERROR`
    var urlValidasiSpt = this.props.envStore.env.urlValidasiSpt;
    try {
      let codeParam = this.getCode();
      console.log(codeParam, "Code")
      sessionStorage.setItem('codeParam', codeParam);
      let res = await service.getDjpAuthorize(codeParam);
      let { data, status, statusText } = res.data;
      if (status=200){
        window.location.href = `${urlValidasiSpt}/auth/konfirmasi-spt`
      }
      console.log(res)
      console.log(res.data)
    } catch (error) {
      console.log("Error")
      console.log(error)
      console.log(error.message)
      if(error.message=="Login gagal. Silakan untuk melakukan proses ulang!"){
        alert(error.message);
        // window.location.href = `${urlValidasiSpt}/auth/validasi-spt?token=${authService.getAccessToken()}&widgetClientId=${authService.getWidgetClientId()}`
      }
      else{
        console.log("else")
        alert(error.message);
      }
      // alert(error.message);
      // window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${error.message}`
    }
  }

  

  getConfigHeadersAndHost = () => {      
    http.setClient(authService.getWidgetClientId());
    http.setHost(this.props.envStore.env.widgetGateway.host);
  }

  getCode = () => {
    const parsedUrl = qs.parseUrl(window.location.href);
    let { code } = parsedUrl.query;

    return code;
  }

  render(history, navigationStore){
    let asMobile = false;
    
    return(
      <div class="vs-layout">
        <div class="vs-content">
          <Logo/>
          LOADING AUTH CHECK ...
        </div>
      </div>
    )
  }
}

export default inject('envStore')(observer(AuthCheck));
/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import Script from 'react-load-script'
import { Button } from 'react-md';
import { inject, observer } from 'mobx-react';

class Testing extends Component{

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }
   
  handleScriptError() {
    this.setState({ scriptError: true })
  }
   
  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  onclose(event){
    console.log("HTML")
    console.log("onclose")
  }

  onClick(event){
    console.log("onClick")
    console.log(origin, "Origin")
    window.parent.postMessage({eventName:'close-window', data:{nama:'Fajar'}}, origin);
    // window.postMessage({eventName:'close-window', data:{nama:'Fajar'}}, 'http://localhost:3030/');
  }
  
  render(){

    let urlValidasiSpt = this.props.envStore.env.urlValidasiSpt;
    let urlDjp = this.props.envStore.env.urlDjp;
    
    return(
      <>
      <Script
        url="http://localhost:8080/static/libs/testing-widget.js"
        onCreate={this.handleScriptCreate.bind(this)}
        onError={this.handleScriptError.bind(this)}
        onLoad={this.handleScriptLoad.bind(this)}
      />
      <div className="mpk-layout column fill mpk-padding-N padding-all">
        TESTING
        <br/>
          
        <Button 
          className="hasil-vs-button"
          onClick={() => {
            // this.onClick();
            // alert("Selesai");
            // window.close();
            window.location.href = "${urlDjp}/oauth2/authorize?response_type=code&client_id=ecf01646-f9be-4e2d-84f9-1ff2dde61ccd&redirect_uri=${urlValidasiSpt}/widget/auth/check";
          }}
        >
          Klik
        </Button>
      </div>
      </>
    )
  }
}

export default inject('envStore')(observer(Testing));
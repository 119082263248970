/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import Logo from '../../libs/react-mpk/components/Logo'
import MitraDJP from '../../libs/react-mpk/components/MitraDJP'
import LocaleSwitcher from '../../libs/react-mpk/components/LocaleSwitcher'
import { CircularProgress, Button } from 'react-md';
import './ValidasiSpt.scss';
import qs from 'query-string';
import t from 'counterpart';
import authService from '../../services/authService';
import { utilsService } from 'services';
import { inject, observer } from 'mobx-react';
import { http } from '../../libs/react-mpk/services';


class ValidasiSpt extends Component{
  async componentWillMount(){
    try{
      console.log('22');
      
    }catch(error){
      console.log(error)
    }
  }

  render(history, navigationStore){

    let urlValidasiSpt = this.props.envStore.env.urlValidasiSpt;
    let urlDjp = this.props.envStore.env.urlDjp;
    
    return(
      // <div className="mpk-layout column fill mpk-padding-N padding-all">
      //   Welcome to Validasi SPT main Widget
      // </div>
      <div class="vs-layout">
        <div class="vs-content">
          <Logo/>
          <div class="vs-desc">
            Kami akan membantu anda untuk melakukan validasi data perpajakan anda.
            <br/>
            <br/>
            Klik tombol dibawah dan login ke akun DJP Online anda untuk memberikan otorisasi kepada kami untuk melakukan validasi data SPT anda.
          </div>
        <Button 
          className="vs-button"
          onClick={() => {
            console.log("onClick")
            console.log(origin, "origin")
            console.log(sessionStorage.getItem('origin'), "origin Session storage")
            // window.opener.postMessage({eventName:'close-window', data:{nama:'Fajar'}}, sessionStorage.getItem('origin'));
            // window.close();
            // window.location.href = "${urlValidasiSpt}/auth/check?code=012f8a8d1c87";
            // window.location.href = "${urlDjp}/oauth2/authorize?response_type=code&client_id=ecf01646-f9be-4e2d-84f9-1ff2dde61ccd&redirect_uri=${urlValidasiSpt}/auth/check";
            window.location.href = `${urlDjp}/oauth2/authorize?response_type=code&client_id=ecf01646-f9be-4e2d-84f9-1ff2dde61ccd&redirect_uri=${urlValidasiSpt}/auth/check`;
          }}
        >
          {/* {t.translate('custom.sentences.checkTransactions')} */}
          Masuk dengan Akun DJP Online
        </Button>
        </div>
      </div>
    )
  }
}

export default inject('envStore')(observer(ValidasiSpt));
import React, { useEffect, useState } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { LoaderInfo } from "../libs/react-mpk/components";
import ValidasiSpt from '../modules/ValidasiSpt';
import ValidasiSptError from '../modules/ValidasiSptError';
import KonfirmasiSpt from '../modules/KonfirmasiSpt';
import HasilSpt from '../modules/HasilSpt';
import HasilSptFailed from '../modules/HasilSptFailed';
import AuthCheck from '../modules/AuthCheck';
import qs from 'query-string';
import authService from '../services/authService';
import { http } from '../libs/react-mpk/services';
import { inject, observer } from 'mobx-react';

const Widget = ({history, ...props}) => {
  const [isLoading, setIsLoading] = useState(true)
  var urlValidasiSpt = props.envStore.env.urlValidasiSpt;
  // console.log("🚀 ~ file: Widget.routes.js ~ line 17 ~ Widget ~ urlValidasiSpt", urlValidasiSpt)

  useEffect(()=>{
    checkingAuth();
  }, [])

  const checkingAuth = () => {

    const parsedUrl = qs.parseUrl(window.location.href);

    let { 
      npwp, nomorBps, tahunPajak, nilai, token, accessKey, origin, hostRefNo, client_id, company_id
    } = parsedUrl.query;
    
    if(npwp) sessionStorage.setItem('npwp', npwp);
    if(nomorBps) sessionStorage.setItem('nomorBps', nomorBps);
    if(tahunPajak) sessionStorage.setItem('tahunPajak', tahunPajak);
    if(nilai) sessionStorage.setItem('nilai', nilai);
    if(hostRefNo) sessionStorage.setItem('hostRefNo', hostRefNo);
    if(client_id) sessionStorage.setItem('client_id', client_id);

    if(origin) sessionStorage.setItem('origin', origin);

    // if(origin) sessionStorage.setItem('origin', window.location.origin);
    
    // console.log(
    //   sessionStorage.getItem('npwp')+", "+
    //   sessionStorage.getItem('nomorBps')+", "+
    //   sessionStorage.getItem('tahunPajak')+", "+
    //   sessionStorage.getItem('nilai')+", "+
    //   sessionStorage.getItem('origin')
    // );

    // console.log("origin")
    // console.log(origin)
    // console.log(sessionStorage.getItem('origin'))

    // if(npwp) sessionStorage.setItem('npwp', npwp);

    // console.log("npwp")
    // console.log(npwp)
    // console.log(sessionStorage.getItem('npwp'))
    
    let ignoreAuth = false;
    
    if(token || accessKey) authService.clearCookie();
    if(token) authService.setAccessToken(token);
    if(accessKey) authService.setAccessKey(accessKey);

    // if(widgetClientId) authService.clearCookieWidgetClientId();
    // if(widgetClientId) authService.setWidgetClientId(widgetClientId);

    // console.log('client_id', sessionStorage.getItem('client_id'));
    authService.clearCookieWidgetClientId();
    if(client_id){
      authService.setWidgetClientId(client_id);
    }
    authService.clearCookieCompanyId();
    if(company_id){
      authService.setCompanyId(company_id);
    }

    http.setClient(sessionStorage.getItem('client_id'));
    http.setHost(props.envStore.env.widgetGateway.host);    

    // console.log("AccessToken");
    // console.log(authService.getAccessToken());
    // console.log("Widged Client ID");
    // console.log(authService.getWidgetClientId());
    // console.log("Widged HOST");
    // console.log(props.envStore.env.widgetGateway.host);
    let error;
    if(authService.getAccessToken()){
      // alert("AKSES TOKEN")
      if(!authService.getCompanyId()){
        alert("Company ID Tidak ada !!!")
        error = 'Company ID Tidak Boleh Kosong'
        window.location.href = `${urlValidasiSpt}/validasi-spt-error?respMessage=${error}`
        // window.close();
      }
      http.setRequestInterceptor(config => {
        // config.headers = config.headers || {}
        config.headers.Authorization = `Bearer ${authService.getAccessToken()}`
        config.headers['X-COMPANY'] = `${authService.getCompanyId()}`
        return config
      })
      setIsLoading(false)
    }
    else if(authService.getAccessKey()){
      // alert("AKSES KEY")
      http.setRequestInterceptor(config => {
        // config.headers = config.headers || {}
        config.headers['X-ACCESS-KEY'] = `${authService.getAccessKey()}`
        if(authService.getCompanyId()){
          config.headers['X-COMPANY'] = `${authService.getCompanyId()}`
        }
        // console.log(config);
        return config
      })
      setIsLoading(false)
    } else {
      alert("Akses Token Tidak ada !!!")
      error = 'Access Token or Access Key is Mandatory'
      window.location.href = `${urlValidasiSpt}/validasi-spt-error?respMessage=${error}`
      // window.close();
      // window.location = '/auth/login'
    }

    if(authService.getCompanyId()){
      // alert("AKSES KEY")
      http.setRequestInterceptor(config => {
        config.headers = config.headers || {}
        config.headers['X-COMPANY'] = `${authService.getCompanyId()}`
        config.headers.Authorization = `Bearer ${authService.getAccessToken()}`
        console.log(config);
        return config
      })
    }

    if(!authService.getWidgetClientId()){
      alert("Client ID Tidak ada !!!")
      error = 'Client ID Tidak Boleh Kosong'
      window.location.href = `${urlValidasiSpt}/validasi-spt-error?respMessage=${error}`
      // window.close();
    }

  }



  return isLoading ? (<LoaderInfo/>) : (
    <Router history={history}>
      <Switch>

        <Route path="/auth/validasi-spt" render={(props) => (
          <ValidasiSpt {...props}/>
        )}/>
        <Route path="/auth/validasi-spt-error" render={(props) => (
          <ValidasiSptError {...props}/>
        )}/>

        <Route path="/auth/konfirmasi-spt" render={(props) => (
          <KonfirmasiSpt {...props}/>
        )}/>

        <Route path="/auth/hasil-spt" render={(props) => (
          <HasilSpt {...props}/>
        )}/>

        <Route path="/auth/hasil-spt-failed" render={(props) => (
          <HasilSptFailed {...props}/>
        )}/>

        <Route path="/auth/check" render={(props) => (
          <AuthCheck {...props}/>
        )}/>

      </Switch>
    </Router>
  )
}


export default inject('envStore')(observer(Widget));
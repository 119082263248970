/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import Logo from '../../libs/react-mpk/components/Logo'
import MitraDJP from '../../libs/react-mpk/components/MitraDJP'
import LocaleSwitcher from '../../libs/react-mpk/components/LocaleSwitcher'
import { CircularProgress, Button } from 'react-md';
import { Md3DRotation } from "react-icons/md";
import './ValidasiSptError.scss';
import doneLogo from "../../assets/images/fail.png";
import { size } from 'lodash';
import { inject, observer } from 'mobx-react';
import qs from 'query-string';

class ValidasiSptError extends Component{

  componentDidMount(){
    console.log('aaa');

    // let dataaa = sessionStorage.getItem('dataResponseDjp');
    // console.log("dataResponseDjp")
    // console.log(sessionStorage.getItem('dataResponseDjp'))
    // console.log(dataaa)
    // console.log(dataaa.data)
    
  }
  
  onClose = () => {
    try {
      let origin = sessionStorage.getItem('origin');
      let responseDataDjp = {
        res: sessionStorage.getItem('responseDataDjpFull'),
        data: sessionStorage.getItem('responseDataDjp'),
        status: sessionStorage.getItem('responseStatusDjp')
      }
      // let responseDataDjp = {
      //   data: "Validasi berhasil",
      //   status: "success"
      // }
      
      console.log(responseDataDjp);

      window.opener.postMessage({eventName:'close-window', responseDataDjp}, origin);
      window.close();

    } catch (error) {
      console.log("Gagal")
      console.log(error);
      console.log(error.message);
      alert(error.message);
      window.close();
    }
  }

  render(history, navigationStore){
    // let { isLoading } = this.state;
    // let { tmpObject } = this.props;
    // let { asMobile } = tmpObject
    let asMobile = false;
    let noRef = "406radikpywq7xz"

    const parsedUrl = qs.parseUrl(window.location.href);
    
    let { 
      respMessage
    } = parsedUrl.query;
    
    return(
      // <div className="mpk-layout column fill mpk-padding-N padding-all">
      //   Welcome to Validasi SPT main Widget
      // </div>
      <div class="hasil-vs-layout">
        <div class="hasil-vs-content">
          <div class="hasil-vs-desc">
            <Logo/>
            <br/>
            <img src={doneLogo} alt="done" style={{height:"100px"}}/>
            <br/>
            <b><h3>Validasi Failed</h3></b>
            <br/>
            <div style={{marginTop:"-25px"}}>
              {respMessage}
            </div>
            {/* <br/>
            <div>
              <b>{noRef}</b>
            </div> */}
            <br/>
            <Button 
              className="hasil-vs-button"
              onClick={() => {
                this.onClose();
              }}
            >
              Tutup dan Kembali ke Aplikasi sebelumnya
            </Button>
            
          </div>
        </div>
      </div>
    )
  }
}

export default inject('envStore')(observer(ValidasiSptError));
import hosts from './hosts';
import numeral from 'numeral';

let reqOptions = {
  clientId: null,
  accessToken: null,
  accessKey: null,
  host: null
}

const utilsService = {

  // '4/30/2018' to 1525021200000
  slashDateToMillis: (str,isStart) => {
    var h = 59, m = 59, s = 59, ms = 999;
    if(isStart){
      h = 0; m = 0; s = 0; ms = 0;
    }
    let p = str.split('/')
    let d = new Date(parseInt(p[2], 0), parseInt(p[0], 0)-1, parseInt(p[1], 0), h, m, s, ms)
    return d.getTime()
  },

  mapUrlParams: params => {
    let newParams = {};
    let switcher = {
      page: "page",
      column: "field",
      sortBy: "column",
      sort: "order",
      size: "size",
      keyword: "query",
      startDate: "startDate",
      endDate: "endDate"
    };

    let keys = Object.keys(switcher);
    for(let i = 0 ; i < keys.length ; i++){
      let key=keys[i];
      if(params[key]){
        if(key==='startDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], true );
        }else if(key==='endDate'){
          newParams[switcher[key]] = utilsService.slashDateToMillis( params[key], false );
        }else{
          newParams[switcher[key]] = params[key];
        }
      }
    }

    return newParams;
  },
  cloneObject: object => {
    return JSON.parse(JSON.stringify(object));
  },
  setReqOptionClientId: clientId => {
    reqOptions.clientId = clientId;
  },
  setReqOptionAccessToken: accessToken => {
    reqOptions.accessToken = accessToken;
  },
  setReqOptionAccessKey: accessKey => {
    reqOptions.accessKey = accessKey;
  },
  setReqOptionHost: host => {
    reqOptions.host = host;
  },
  getReqOptionHost: () => {
    return reqOptions.host;
  },
  generateRequestOptions: (path) => {
    let { clientId, accessToken, accessKey, host } = reqOptions;
    let headers = {'X-Client': clientId };
    if(accessKey) headers['X-Access-Key'] = accessKey;
    else if(accessToken) headers['Authorization'] = `Bearer ${accessToken}`;
    let options = {
      // url: `${host}${path}`,
      url: `${path}`,
      config: {headers}
    }
    return options;
  },
  parseNumber: value => {
    return numeral(value).format('0,0').replace(/\,/g, '&').replace(/\./g, ',').replace(/&/g, '.')
  },
};

export default utilsService;
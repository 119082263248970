/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import Script from 'react-load-script'
import { Button } from 'react-md';
import { inject, observer } from 'mobx-react';

class Main extends Component{

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }
   
  handleScriptError() {
    this.setState({ scriptError: true })
  }
   
  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  onclose(event){
    console.log("HTML")
    console.log("onclose")
    console.log(event)
  }
  
  render(){

    let urlValidasiSpt = this.props.envStore.env.urlValidasiSpt;
    let urlDjp = this.props.envStore.env.urlDjp;
    
    return(
      <>
      <Script
        url="http://localhost:3030/static/libs/validasi-spt-widget.js"
        onCreate={this.handleScriptCreate.bind(this)}
        onError={this.handleScriptError.bind(this)}
        onLoad={this.handleScriptLoad.bind(this)}
      />
      <div className="mpk-layout column fill mpk-padding-N padding-all">
        Welcome to Validasi SPT Widget
        <br/>
      </div>
      </>
    )
  }
}

export default inject('envStore')(observer(Main));
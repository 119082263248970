import { http } from '../../libs/react-mpk/services';
import { utilsService } from 'services';

export const postValidateSpt = (data, hostRefNo) => {
  // console.log(data, "data")
  // console.log(hostRefNo, "hostRefNo")
  let options = utilsService.generateRequestOptions(`/validasi_spt/djp/validate/omzet`);
  if (hostRefNo){
    options.config.params={
      hostRefNo:hostRefNo
    }
  }
  options.data = data;
  options.method = 'post';
  console.log(options);
  return http.request(options);
}
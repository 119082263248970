/**
 * Created by dwiargo on 10/11/17.
 */

import cookie from 'react-cookies';

let roles = [];
let logoutAction; //Function
let hasChangeTokenNames = false;
let tokenNames = {
  accessToken: 'MPK_ACCESS_TOKEN',
  refreshToken: 'MPK_REFRESH_TOKEN',
  accessKey: 'MPK_ACCESS_KEY',
  code: 'MPK_CODE',
  userProfile: 'USER_PROFILE',
  widgetClientId: 'WIDGET_CLIENT_ID',
  widgetHost: 'WIDGET_HOST',
  companyId: 'COMPANY_ID'
}

const authService = {
  setTokenNames: (accessToken=tokenNames.accessToken, refreshToken= tokenNames.refreshToken, code=tokenNames.code, accessKey=tokenNames.accessKey) => {
    if(!hasChangeTokenNames){
      tokenNames = {accessToken, refreshToken, code, accessKey}
      hasChangeTokenNames = true;
    }
  },
  setLogoutAction: newLogoutAction => {
    logoutAction = newLogoutAction;
  },
  logout: (callback, redirectUri) => {
    if(logoutAction) logoutAction(redirectUri, () => {
      if(callback) callback();
      authService.clearCookie();
    });
    else {
      authService.clearCookie();
    }
  },
  getMe: (callback) => {

  },
  clearCookie:() => {
    console.log('hapus semua cookie');
    cookie.remove(tokenNames.accessToken, {path:'/'});
    cookie.remove(tokenNames.refreshToken, {path:'/'});
    cookie.remove(tokenNames.accessKey, {path:'/'});
    cookie.remove(tokenNames.userProfile, {path:'/'});
    cookie.remove(tokenNames.widgetClientId, {path:'/'});
    cookie.remove(tokenNames.companyId, {path:'/'});
  },
  
  clearCookieWidgetClientId:() => {
    cookie.remove(tokenNames.widgetClientId, {path:'/'});
  },
  setWidgetClientId: widgetClientId => cookie.save(tokenNames.widgetClientId, widgetClientId),
  getWidgetClientId: () => cookie.load(tokenNames.widgetClientId),
  
  clearCookieWidgetHost:() => {
    cookie.remove(tokenNames.widgetHost, {path:'/'});
  },
  setCompanyId: companyId => cookie.save(tokenNames.companyId, companyId),
  getCompanyId: () => cookie.load(tokenNames.companyId),
  
  clearCookieCompanyId:() => {
    cookie.remove(tokenNames.companyId, {path:'/'});
  },
  setWidgetHost: widgetHost => cookie.save(tokenNames.widgetHost, widgetHost),
  getWidgetHost: () => cookie.load(tokenNames.widgetHost),
  
  getAccessToken: () => cookie.load(tokenNames.accessToken),
  getRefreshToken: () => cookie.load(tokenNames.refreshToken),
  getAccessKey: () => cookie.load(tokenNames.accessKey),
  getUserProfile: () => cookie.load(tokenNames.userProfile),
  setAccessToken: access_token => cookie.save(tokenNames.accessToken, access_token, {path:'/'}),
  setAccessKey: accessKey => cookie.save(tokenNames.accessKey, accessKey),
  setUserProfile: user => {
    let { username, id, email, name } = user;
    cookie.save(tokenNames.userProfile, JSON.stringify({username, id, email, name}), {path:'/'})
  },
  removeUserProfile: () => cookie.remove(tokenNames.userProfile, {path:'/'}),
  getCode: () => cookie.load(tokenNames.code),
  setCode: code => cookie.save(tokenNames.code, code, {path:'/'}),
  getSession: code => cookie.load('JSESSIONID')
};

export default authService;
/**
 * Created by dwiargo on 11/29/17.
*/

import React, {Component, useState} from 'react'
import Logo from '../../libs/react-mpk/components/Logo'
import MitraDJP from '../../libs/react-mpk/components/MitraDJP'
import LocaleSwitcher from '../../libs/react-mpk/components/LocaleSwitcher'
import { CircularProgress, Button } from 'react-md';
import { inputTypes } from '../../libs/react-mpk/config/constant'
import { FormWrapper } from '../../libs/react-mpk/wrapper'
import './KonfirmasiSpt.scss';
import { utilsService, authService } from 'services';
import { inject, observer } from 'mobx-react';
import * as service from './KonfirmasiSptService';
import { http } from '../../libs/react-mpk/services';

class KonfirmasiSpt extends Component{  

  componentDidMount(){
    
  }

  defaultData = () => {
    let defaultData = {
      npwp: sessionStorage.getItem('npwp'),
      noBps: sessionStorage.getItem('nomorBps'),
      tahunPajak: sessionStorage.getItem('tahunPajak'),
      nilai: sessionStorage.getItem('nilai')
    }
    return defaultData;
  }

  getConfigHeadersAndHost = () => {
    utilsService.setReqOptionHost(this.props.envStore.env.widgetGateway.host)
    if(authService.getWidgetClientId()) utilsService.setReqOptionClientId(authService.getWidgetClientId());
    if(authService.getAccessToken()) utilsService.setReqOptionAccessToken(authService.getAccessToken());
    if(authService.getAccessKey()) utilsService.setReqOptionAccessKey(authService.getAccessKey());
  }

  onSubmit = async (dataForm) => {
    var urlValidasiSpt = this.props.envStore.env.urlValidasiSpt;
    try {


      let res = await service.postValidateSpt(dataForm, sessionStorage.getItem('hostRefNo'));
      
      let { data, status, message } = res.data;
      
      sessionStorage.setItem('responseDataDjpFull', `${JSON.stringify(res.data)}`);
      sessionStorage.setItem('responseStatusDjp', status);

      console.log(res, "RES")
      console.log(res.data, "RES DATA")
      
      if (status=='success'){
        alert("Berhasil")
        sessionStorage.setItem('responseDataDjp', `Berhasil (${data})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt?respMessage=${data}`
      }
      else if (status=='fail'){
        let messageFail = "Salah satu data yang anda masukkan tidak benar, sehingga tidak dapat diverifikasi"
        alert("Failed : \n"+messageFail)
        sessionStorage.setItem('responseDataDjp', `FAILED (${messageFail})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${messageFail}`
      } 
      else if (status=='error'){
        alert("ERROR : \n"+message)
        sessionStorage.setItem('responseDataDjp', `FAILED (${message})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${message}`
      }else if (status == 'INSUFFICIENT BALANCE'){
        let messageFail = "Saldo Limit tidak mencukupi"
        alert("ERROR : \n"+messageFail)
        sessionStorage.setItem('responseDataDjp', `FAILED (${messageFail})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${messageFail}`
      }else if (status == 'LIMIT REACHED'){
        let messageFail = "Saldo telah mencapai LIMIT"
        alert("ERROR : \n"+messageFail)
        sessionStorage.setItem('responseDataDjp', `FAILED (${messageFail})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${messageFail}`
      }
      else{
        let altMsg = 'Something Error';
        alert("OTHERS : \n"+message?message:altMsg)
        sessionStorage.setItem('responseDataDjp', `FAILED (${message?message:altMsg})`);
        window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${message?message:altMsg}`
      }

    } catch (error) {
      console.log("Gagal")
      console.log(error);
      console.log(error.message);
      alert("GAGAL : \n"+error.message)
      sessionStorage.setItem('responseDataDjpFull', `${JSON.stringify(error)}`);
      sessionStorage.setItem('responseDataDjp', `FAILED (${error?.message})`);
      sessionStorage.setItem('responseStatusDjp', 'fail');
      window.location.href = `${urlValidasiSpt}/auth/hasil-spt-failed?respMessage=${error?.message}`
    }
  }

  render(history, navigationStore){
    
    return(
      <div class="vs-layout">
        <div class="vs-content">
          {/* <Logo/> */}
          <FormWrapper
            hintMessage="Konfirmasi SPT"
            submitLabel="Validasi Data"
            // loading={data.loading}
            showCommandbar={false}
            baseId={`mod-form-konfirmasi-spt-1`}
            style={{
            maxWidth: '100%'
            }}
            defaultData={this.defaultData()}
            onChange={(formData, key, value)=> {
            }}
            definitions={[
              // {
              //   inputType: inputTypes.INPUT,
              //   label: "Nama",
              //   key: 'nama',
              //   type: 'text',
              //   disabled:true,
              //   width: '100%'
              // },
              {
                inputType: inputTypes.INPUT_MASK_NUMBER,
                label: "NPWP",
                key: 'npwp',
                type: 'text',
                // disabled:true,
                mask: '##.###.###.#-###.###',
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: "NTTE / Nomor BPS",
                key: 'noBps',
                type: 'text',
                // disabled:true,
                // mask: inputMasks,
                // maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: "Tahun Pajak",
                key: 'tahunPajak',
                type: 'number',
                // disabled:true,
                // mask: inputMasks,
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              },
              {
                inputType: inputTypes.INPUT,
                label: "Peredaran Bruto",
                key: 'nilai',
                type: 'number',
                // disabled:true,
                // mask: inputMasks,
                maskChar: '_',
                maskValue: 'string',
                width: '100%'
              }
            ]}
            onSubmit={(data, callback) => {
              // console.log(data);
              // console.log(this.defaultData());
              // setTimeout(callback, 2000)
              this.onSubmit(data);
            }}
          />
        </div>
      </div>
    )
  }
}
 
export default inject('envStore')(observer(KonfirmasiSpt));
import { http } from '../../libs/react-mpk/services';
import { utilsService } from 'services';

export const getDjpAuthorize = (code) => {
  console.log("Masuk = " +code);
  let options = utilsService.generateRequestOptions(`/validasi_spt/djp/authorize/${code}`);
  options.method = 'get';
  console.log(options);
  return http.request(options)
  // return http.get(options);
}